import React from "react";
import { Image, Box, Heading } from "@chakra-ui/react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Transformations = () => {
  const settings = {
    dots: false,
    adaptiveHeight: true,
    arrows: false,
    infinite: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    autoplay: true,
    speed: 5000,
    autoplaySpeed: 0,
    //     rtl: true,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const fiveNine = Array.from({ length: 59 }, (_, i) => i + 1);

  return (
    <Box py={6} m={0} bg={"gray.50"}>
      <Heading textAlign={"center"} py={4}>
        20K+ Transformations
      </Heading>
      <Slider {...settings}>
        {fiveNine.map((i) => (
          <Box key={i} px={2}>
            <Image
              src={`https://tdspro.in-maa-1.linodeobjects.com/transformations/wl-${i}.png`}
              border={"2px"}
              borderColor={"white"}
              borderRadius={"lg"}
            />
          </Box>
        ))}
      </Slider>
    </Box>
  );
};

export default Transformations;
